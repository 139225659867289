$(document).ready(function() {

    // Mobile navigation
	$(function() {
		$('#nav-menu-btn, #menu-overlay').click(function(e) {
			e.preventDefault();
			toggleNav();
		});
	});

	// Map cover remover
	$('.map-cover').click(function () {
		$(this).fadeOut();
	});

	// Navigation button
	function toggleNav() {
		if ($('#site-wrapper').hasClass('show-nav')) {
			// Do things on Nav Close
			$('#site-wrapper').removeClass('show-nav');
			$('#menu-overlay').fadeOut();
			$('#nav-menu-btn i').removeClass('fa-times').addClass('fa-bars');
		} else {
			// Do things on Nav Open
			$('#menu-overlay').fadeIn();
			$('#site-wrapper').addClass('show-nav');
			$('#nav-menu-btn i').removeClass('fa-bars').addClass('fa-times');
		}
	}

	// Example hover cover
	// $('.example').hover(function(e) {
	// 	e.preventDefault();
	// 	var workinfo = $(this).find('.work-info');
	// 	if(workinfo.hasClass('visible')){
	// 		workinfo.removeClass('visible').fadeOut();
	// 	} else {
	// 		workinfo.addClass('visible').fadeIn();
	// 	}
	// });

	function isOverflowed(element){
    	return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
	}

	$('.switch-btn').click(function(e) {
		e.preventDefault();
		var employee = $(this).parents('.employee');
		if(employee.hasClass('jekyll')){
			employee.removeClass('jekyll').addClass('hyde');
			employee.find('.jekyll-bio').hide();
			employee.find('.hyde-bio').fadeIn();
			employee.find('.employee-image').hide();
			employee.find('.hyde-photo').fadeIn();
		} else {
			employee.removeClass('hyde').addClass('jekyll');
			employee.find('.hyde-bio').hide();
			employee.find('.jekyll-bio').fadeIn();
			employee.find('.hyde-photo').hide();
			employee.find('.employee-image').fadeIn();
		}
	});

	// Show/hide the tiles of work in the grid
	function filter_works(list){
		if (list[0] == 'all'){
			$works.fadeIn();
		} else {
			$works.not('.work-'+show_work_categories.join(',.work-')).fadeOut();
			$works.filter('.work-'+show_work_categories.join(',.work-')).fadeIn();
		}
	}

	// Add/remove classes from clickable category list
	function adjust_filter_classes(){
		$('.filter-btn').addClass('off').filter('#'+show_work_categories.join(',#')).addClass('on').removeClass('off');
		if ($.inArray('all', show_work_categories) > -1)
			$('.filter-btn').removeClass('off').addClass('on');
		filter_works(show_work_categories);
	}

	var show_work_categories = ['all'];
	var $work_categories = $('.filter-btn');
	var $works = $('.example');

	// Run it. Should start with all
	adjust_filter_classes();

	// Bind click events to each filter.
	$work_categories.click(function(e){
		e.preventDefault();
		if (e.target.id == 'all'){
			show_work_categories = ['all'];
		} else {
			var clicked_filter = $.inArray(e.target.id, show_work_categories);
			// If the clicked element is already in the array remove it, otherwise add it...
			if (clicked_filter > -1)
				show_work_categories.splice(clicked_filter, 1);
			else
				show_work_categories.push(e.target.id);
			// If nothing is selected, default to select all
			if (show_work_categories.length < 1)
				show_work_categories = ['all'];
			// If we just added something specific to `all`, remove `all`.
			if (show_work_categories.length > 1 && show_work_categories[0] == 'all')
				show_work_categories.splice(0,1);
		}
		adjust_filter_classes();
	});

	// Scroll laptop screen when bottom of element is reached
	/*
	var waypoint = new Waypoint({
		element: $('.laptop-screen'),
		handler: function(direction) {
			notify('Waypoint triggered in ' + direction + ' direction');
		},
		offset: 'bottom-in-view'
	});
	*/

	/*
	$('.body').on('click', '.laptop-screen', function(){
		alert("You got it! *snap*");
	});
	*/
	/*
	$('.laptop-screen').waypoint(function(){
	    console.log("Scrolled to the laptop screen");
	}, { context: $('.featherlight-content') });
	*/
	/*
	var waypoint = new Waypoint({
	    element: $('.laptop-screen'),
	    handler: function(direction) {
	        console.log('Scrolled to laptop screen!');
	    }
	});
	*/

});
function refresh_waypoints(e){
	//console.log(e);
	//return false;
	var waypoint = new Waypoint({
		element: $('.laptop-screen'),
		handler: function(direction) {
			if (direction === 'down')
				$('.laptop-screen').addClass('scrolled');
			else
				$('.laptop-screen').removeClass('scrolled');

			console.log(direction+" ... BACON AND EEEEEEGGS!", Math.round(Math.random()*1000));
		},
		context: $('.featherlight-content'),
		offset: '75%'
	});
	console.log(waypoint);
}

var pdfpopup = localStorage.getItem('pdfpopup');
console.log(pdfpopup);
if(!pdfpopup){
    function show7mistakes(){
        $('#mistakes4327').fadeIn(500);
        $('.close-modal').click(function() {
	        $('#mistakes4327').fadeOut(300);
	    });
	    localStorage.setItem('pdfpopup','true');
    }
    setTimeout(function(){
        show7mistakes();
    }, 30000);
}
